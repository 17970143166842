import React from 'react';
import {useLocation, useParams,} from "react-router-dom";
import './AppDataList.css'
import ItemList from "./ItemList";
import {sendErrorAlert} from "./Alert";
import Papa from 'papaparse'
import * as utils from "./utils";
import OptionRender from "./OptionRender";
import CheckboxRender from "./CheckboxRender";
import PrimitiveOptionRender from "./PrimitiveOptionRender";
import {Box, Container, Fab, Grid} from "@mui/material";
import * as _ from "lodash";
import {ColumnSelector} from "./ColumnSelector";
import ItemActions from "./ItemActions";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";
import {SiMicrosoftexcel} from "react-icons/si";

const {confirm} = window;
const DEFAULT_PAGE_SIZE = 100;


class AppDataList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null,
            selectedId: '',
            data: null,
            selectedData: null,
            token: '',
            displayedList: [],
            rowModelType: "infinite",
            add: false,
            schemes: [],
            page: 1,
            startRow: 0,
            endRow: 0,
            pageCount: 0,
            pageSize: DEFAULT_PAGE_SIZE,
            totalEntityCount: 0
        };
        this.handleAdd = this.handleAdd.bind(this);
        this.onFileLoaded = this.onFileLoaded.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.onCellValueChanged = this.onCellValueChanged.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.getDatasource = this.getDatasource.bind(this);
        this.searchData = this.searchData.bind(this);
        this.getData = this.getData.bind(this);
        this.description = {schemes: []};
        this.schemes = [];
        this.gridApi = null;
        this.lastFilter = null;
    }

    componentDidMount() {
        this.computeColumns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps && this.getPath() !== prevProps.match.params.path) {
            this.reloadData();
        }
    }

    getPath() {
        if (this.props.match) {
            return this.props.match.params.path;
        }
        return undefined;
    }

    computeColumns() {
        let path = this.getPath();
        let myFormatter = (key, display) => {
            return (data) => {
                if (!data) {
                    return ''
                }
                let myKey = key;
                if (Array.isArray(data[myKey])) {
                    return data[myKey].length + ' element(s)'
                }
                if (data[myKey] && typeof data[myKey] === 'object') {
                    if (display) {
                        return `${data[myKey][display]} (${data[myKey]["id"]})`
                    }
                    return data[myKey]['id']
                }
                return data[myKey];
            };
        };
        if (path) {
            let description = this.props.descriptions.find(desc => desc.path === path) || {schemes: []};
            this.description = description;
            this.schemes = (description.schemes || []).map(scheme => {
                let formatter = myFormatter(scheme.field, scheme.display);
                if (!scheme.hasOwnProperty("headerName")) {
                    scheme.headerName = _.startCase(scheme.field)
                }
                let cellRenderer = (data) => {
                    let result = formatter(data.data);
                    return typeof result === "undefined" ? "" : result;
                }
                scheme.formatter = formatter;
                if (scheme.field === 'value') {
                    scheme.hide = true;
                }
                //scheme.cellRenderer = cellRenderer;
                if (scheme.provider) {
                    let primitive = utils.isPrimitive(scheme.type);
                    console.log("primitive", primitive)
                    scheme.cellRenderer = primitive ? PrimitiveOptionRender : OptionRender
                    scheme.minWidth = 280;
                } else if (scheme.type === "boolean") {
                    scheme.cellRenderer = CheckboxRender;
                } else {
                    scheme.cellRenderer = cellRenderer;
                }
                scheme.type = utils.getType(scheme.type);
                if (scheme.filter) {
                    scheme.filterParams = {maxNumConditions: 10, defaultJoinOperator: 'OR'};
                }
                return scheme;
            });
            this.schemes.push({
                field: '_action_',
                ignored: true,
                headerName: '/-\\',
                onDownload: this.handleDownload,
                onDelete: this.handleDelete,
                cellRenderer: ItemActions
            });
            this.setState({
                schemes: this.schemes.map(item => {
                    if (item.ignored) {
                        item.hide = true;
                    }
                    return item;
                })
            });
        }
    }

    handleAdd(event) {
        this.setState({
            add: true,
        });
        this.reloadData();
    }

    handleDelete(event) {
        let ok = confirm('Do you want to delete [' + event.data.id + ']');
        if (ok) {
            utils.remove('/api/' + this.getPath() + '/' + event.data.id)
                .then(res => {
                    this.reloadData();
                })
                .catch(res => {
                    let {data: error} = res.response;
                    console.error('error', error);
                });
        }
    }

    handleDownload(event) {
        event.preventDefault();
        this.gridApi.showLoadingOverlay();
        let request;
        if (this.lastFilter) {
            request = this.searchData(this.lastFilter);
        } else {
            request = this.getData(-1, -1, "/list");
        }
        request.then((result: any) => {
            let entities = result.entities || result;
            if (entities.length > 0) {
                let filename = this.getPath().replaceAll("/", "_");
                this.exportToExcelFile(entities, filename);
            }
            this.gridApi.hideOverlay();
        })
            .catch(error => {
                this.gridApi.hideOverlay();
                sendErrorAlert(error);
            });
    }

    exportToExcelFile = (dataItems: any, fileName: any) => {
        let fields = _.groupBy(this.schemes, "field");
        let formattedData = dataItems.map(item => {
            let convertedItem = {};
            for (let field in item) {
                let prop = field;
                if (fields.hasOwnProperty(field)) {
                    prop = fields[field][0].headerName
                }
                convertedItem[prop] = item[field]
                if (typeof item[field] === "object") {
                    convertedItem[prop] = item[field]?.id || JSON.stringify(item[field])
                }
            }
            return convertedItem;
        });

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        const ws = XLSX.utils.json_to_sheet(formattedData);

        const wb = {Sheets: {[fileName]: ws}, SheetNames: [fileName]};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const result = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(result, `${fileName}.xlsx`);
        return 0;
    }

    onFileLoaded(event) {
        const files = this.fileUpload.files;
        console.log(files);
        let reader = new FileReader();
        reader.onload = (e) => {
            // Use reader.result
            let result = Papa.parse(reader.result, {
                header: true,
                skipEmptyLines: true
            });
            let value = result.data.map(item => {
                if (!item.hasOwnProperty("id")) {
                    item.id = null;
                }
                return Object.keys(item).reduce((cumul, key) => {
                    let index = key.indexOf('.id');
                    if (index >= 0) {
                        cumul[key.substr(0, index)] = {
                            id: item[key] * 1
                        }
                    } else {
                        cumul[key] = item[key];
                    }
                    return cumul;
                }, {});
            });
            console.log(JSON.stringify(value));
            utils.post('/api/' + this.getPath() + '/upsert', value, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                return res.data
            })
                .then(response => {
                    if (response.code && response.code / 100 !== 2) {
                        sendErrorAlert({
                            title: 'Upload',
                            message: response.message
                        });
                    } else {
                        this.reloadData();
                    }
                })
                .catch(error => {
                    sendErrorAlert({
                        title: 'Upload',
                        message: error.message
                    });
                });
        }
        reader.readAsText(files[0]);
    }

    validate(data) {
        return this.schemes.map(sch => {
            return !sch.required || Boolean(data[sch.field])
        }).reduce((prev, next) => prev && next, true)
    }

    onCellValueChanged(event) {
        let item = {
            id: event.data.id
        };
        let value = event.value;
        let field = event.colDef.field;
        item[field] = value;
        console.log('event.colDef', event.colDef);
        /*if (!utils.isPrimitive(event.colDef.type) ) {
            item[field] = {id: value * 1};
        }*/
        let fieldValue = item[field];
        let method = utils.put;
        let valid = true;
        if (!Boolean(item.id)) {
            delete item.id;
            item = event.data;
            item[field] = fieldValue
            method = utils.post;
            valid = this.validate(item);
        }
        console.log('item', item);
        if (!valid) return;
        let path = this.getPath();
        method('/api/' + path, item, {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
            .then(res => {
                let {data: response} = res
                console.log('response', response);
                if (response.code && response.code / 100 !== 2) {
                    sendErrorAlert({
                        title: event.colDef.headerName,
                        message: response.message
                    });
                } else {
                    event.node.setData(response);
                    event.api.refreshCells({force: true, rowNodes: [event.node]});
                    if (this.state.add) {
                        this.setState({
                            add: false,
                        });
                    }
                }
            })
            .catch(error => {
                console.log('error', error);
                let {data} = error.response
                sendErrorAlert({
                    title: error.message,
                    message: data.message,
                    timeout: 30 * 1000
                });
            })
    }

    onGridReady(event) {
        console.log('this.schemes', this.schemes);
        console.log('event', event);
        this.gridApi = event.api;
        this.reloadData();
    }

    search(body, params) {
        let url = `/api/${this.getPath()}/search`;
        let select = this.getSelect();
        let query = '';
        if (params) {
            for (const param in params) {
                if (query === '') {
                    query = `${param}=${params[param]}`;
                } else {
                    query = query + `&${param}=${params[param]}`;
                }
            }
        }
        if (query !== '') {
            url = `${url}?${query}&select=${select}`;
        } else {
            url = `${url}?select=${select}`;
        }
        console.log(url);
        return utils.post(url, body, {
            'Content-Type': 'application/json'
        }).then(res => res.data);
    }

    searchData(filter, pageNumber = -1, pageSize = -1) {
        let params = {};
        params['page'] = `${pageNumber}`;
        params['perPage'] = `${pageSize}`;
        return this.search(filter, params);
    }

    getData(pageNumber = -1, pageSize = -1, subpath = "") {
        let select = this.getSelect()
        let url = `/api/${this.getPath()}${subpath}?page=${pageNumber}&perPage=${pageSize}&select=${select}`;
        return utils.get(url).then(res => res.data)
    }

    getSelect() {
        return this.schemes.filter(scheme => !scheme.ignored && !scheme.hide).map(scheme => scheme.field).filter(field => field !== "value").join(',');
    }

    getDatasource() {
        return {
            destroy: () => {
                console.log("destroying....");
            },
            getRows: (params) => {
                const pageNumber = params.endRow / DEFAULT_PAGE_SIZE;
                let request;
                const fields = [];
                let filterModel = params.filterModel;
                if (this.filterModel) {
                    filterModel = Object.assign({}, filterModel, this.filterModel);
                }
                for (const field in filterModel) {
                    if (filterModel.hasOwnProperty(field)) {
                        fields.push(field);
                    }
                }
                const filter = {
                    filterModel: filterModel,
                    sortModel: params.sortModel
                };
                if (fields.length || params.sortModel.length) {
                    this.lastFilter = filter;
                    request = this.searchData(filter, pageNumber, DEFAULT_PAGE_SIZE);
                } else {
                    this.lastFilter = null;
                    request = this.getData(pageNumber, DEFAULT_PAGE_SIZE);
                }
                if (this.gridApi) {
                    this.gridApi.showLoadingOverlay();
                }
                request
                    .then((page) => {
                        console.log('page', page);
                        const totalCount = (page.page - 1) * page.pageSize + (page.entities || []).length;
                        /*if (this.gridApi) {
                            this.gridApi.setInfiniteRowCount(Math.max(totalCount, page.totalEntityCount));
                        }*/
                        let entities = page.entities || []
                        if (this.state.add) {
                            entities = [{}, ...entities];
                            page.totalEntityCount = page.totalEntityCount + 1;
                        }
                        let lastRow = totalCount;
                        if (lastRow >= DEFAULT_PAGE_SIZE) {
                            lastRow = Math.max(totalCount, page.totalEntityCount);
                        }
                        params.successCallback(entities, lastRow);
                        if (this.gridApi) {
                            this.gridApi.hideOverlay();
                        }
                        this.setState({
                            page: page.page,
                            pageSize: page.perPage,
                            pageCount: Math.ceil(lastRow / DEFAULT_PAGE_SIZE),
                            startRow: params.startRow,
                            endRow: Math.min(params.endRow, lastRow),
                            totalEntityCount: lastRow
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        params.failCallback();
                        if (this.gridApi) {
                            this.gridApi.hideOverlay();
                        }
                    });
            }
        };
    }

    reloadData(computeColumns = true) {
        if (this.gridApi && !this.state.adding) {
            this.gridApi.showLoadingOverlay();
            if (computeColumns) {
                this.computeColumns();
            }
            this.gridApi.setColumnDefs(this.schemes);
            this.gridApi.setDatasource(this.getDatasource());
            this.gridApi.hideOverlay();
        }
    }

    handleColumnSelection(schemes) {
        this.schemes = schemes
        console.log("schemes", schemes);
        this.reloadData(false);
    }

    render() {
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2} marginTop={1}>
                    <Grid item md={3} sx={{display: {xs: 'none', md: 'block'}}} marginTop={1}>
                        <ColumnSelector xs={{height: '760px'}}
                                        onSelectionChanged={(schemes) => this.handleColumnSelection(schemes)}
                                        items={this.state.schemes}
                                        path={this.getPath()}/>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Grid container>
                            <Grid container margin={2}>
                                <Grid item xs>
                                    <h2 className="h4">{_.startCase(this.getPath())}</h2>
                                    <strong>{this.state.startRow + 1}</strong> to <strong>{this.state.endRow}</strong> of <strong>{this.state.totalEntityCount}</strong>
                                    {" | "} page <strong>{this.state.page}</strong> of <strong>{this.state.pageCount}</strong>
                                </Grid>
                                <Box display="flex" justifyContent="flex-end">
                                    <Grid item xs marginRight={1}>
                                        <Fab size="small"
                                             color="success"
                                             title={"Add new row"}
                                             aria-label="add"
                                             disabled={this.state.add}
                                             onClick={this.handleAdd}>
                                            <AddIcon/>
                                        </Fab>
                                    </Grid>
                                    <Grid item xs marginRight={1}>
                                        <input id="file-input" onChange={this.onFileLoaded}
                                               type="file" accept={"text/csv"}
                                               name="name" style={{display: "none"}}
                                               ref={(ref) => this.fileUpload = ref}/>
                                        <Fab size="small"
                                             color="info"
                                             title={"Upload CSV file"}
                                             aria-label="upload"
                                             onClick={(e) => this.fileUpload.click()}>
                                            <UploadIcon/>
                                        </Fab>
                                    </Grid>
                                    <Grid item xs>
                                        <Fab size="small"
                                             color="error"
                                             title={"Export to Excel"}
                                             aria-label="download"
                                             onClick={this.handleDownload}>
                                            <SiMicrosoftexcel/>
                                        </Fab>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <ItemList style={{height: '760px', width: '100%'}}
                                    // items={this.state.data}
                                          rowModelType={"infinite"}
                                    // headers={this.schemes}
                                          onGridReady={this.onGridReady}
                                          onCellValueChanged={this.onCellValueChanged}/>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const params = useParams();

        return (
            <Component
                {...props}
                match={{params}}
                router={{location}}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter(AppDataList);