import React from "react";
import {ICellRendererReactComp} from "ag-grid-react";

import * as  utils from "./utils";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";

export default class OptionRender extends React.Component implements ICellRendererReactComp {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            options: [],
            page: 1,
            perPage: 20,
            loading: false,
            open: false,
        }
        this.controller = new AbortController();
    }

    handleChange(event, value) {
        if (value) {
            this.props.setValue({
                [this.props.colDef.provider.value]: value?.value || "",
                [this.props.colDef.provider.label]: value?.label || ""
            });
        } else {
            this.props.setValue(null);
        }
        this.props.colDef.inputValue = "";
    }

    handleInputChange(event, value) {
        this.props.colDef.inputValue = value;
        if (this.state.open) {
            this.loadData.call(this).then(options => {
                this.setState({
                    options
                })
            });
        }
    }

    get value() {
        if (!this.props.value) {
            return null;
        }
        return {
            value: this.props.value[this.props.colDef.provider.value] || "",
            label: this.props.value[this.props.colDef.provider.label] || ""
        }
    }

    async loadData() {
        try {
            this.setState({
                loading: true
            });
            let provider = this.props.colDef.provider;
            let inputValue = this.props.colDef.inputValue || ""
            let value = provider.value;
            let label = provider.label;
            let select = Boolean(provider.select) ? provider.select : provider.label;
            console.log("select", select);
            let filter = Boolean(provider.filter) ? provider.filter : select;
            let path = `/api/${provider.path}?page=${this.state.page}&perPage=${this.state.perPage}&select=${value},${select}`;
            let or = filter.split(";").map(f => {
                path = `${path}&${f.trim()}_IN_=${encodeURI(inputValue)}`
                return `${f.trim()}_IN_`;
            }).join(";");
            path = `${path}&or=${or}`
            let currentValue = this.value;
            console.log(currentValue)
            if (currentValue?.value && !Boolean(inputValue) && !Boolean(currentValue.label)) {
                path = `${path}&${value}=${currentValue.value}`
            }
            console.log(path)
            let response = await utils.get(path, {
                signal: this.controller.signal
            });
            let options = response.data.map(item => {
                return {value: item[value], label: item[label]}
            });
            options.sort((a, b) => a.label.localeCompare(b.label));
            return options;
        } catch (e) {
            console.log(e);
            return this.state.options
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!prevState.open && this.state.open) {
            try {
                let currentValue = this.value;
                if (currentValue) {
                    this.props.colDef.inputValue = currentValue.label;
                }
                let options = await this.loadData.call(this);
                this.setState({options});
            } catch (e) {
                console.log(e);
            }
        }
    }

    render() {
        return (
            <Autocomplete
                id={this.props.colDef.field}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                value={this.value}
                loading={this.state.loading}
                open={this.state.open}
                onOpen={() => {
                    this.setState({
                        open: true
                    });
                }}
                onClose={() => {
                    this.setState({
                        open: false
                    });
                }}
                isOptionEqualToValue={(opt, val) => opt.value === val.value}
                getOptionLabel={option => `${option.label || option.value}`}
                options={this.state.options}
                renderOption={(props, option) => <option {...props} key={option.value}
                                                         value={option.value}>{option.label}</option>}
                renderInput={
                    (params) => <TextField {...params}
                                           // label={this.props.colDef.headerName}
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <React.Fragment>
                                                       {this.state.loading ?
                                                           <CircularProgress color="inherit" size={20}/> : null}
                                                       {params.InputProps.endAdornment}
                                                   </React.Fragment>
                                               ),
                                           }}
                    />
                }
            />
        );
    }
}