import React from "react";
import {ICellRendererReactComp} from 'ag-grid-react'
import {Grid} from "@mui/material";
import ItemDelete from "./ItemDelete";

export default class ItemActions extends React.Component implements ICellRendererReactComp {
    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs>
                    <ItemDelete {...this.props}/>
                </Grid>
            </Grid>
        );
    }
}