import React from "react";
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default class ItemList extends React.Component {

    render() {
        //let headers = this.state.headers || this.props.headers || [];
        //let items = this.state.items || this.props.items || [];
        return (
            <div className="ag-theme-alpine" style={{...this.props.style}}>
                <AgGridReact
                    defaultColDef={{
                        resizable: true,
                    }}
                    sideBar={{
                        toolPanels: ['columns'],
                        position: 'left'
                    }}
                    autoGroupColumnDef={{
                        minWidth: 200,
                    }}
                    rowSelection={'multiple'}
                    rowGroupPanelShow='always'
                    pivotPanelShow="always"
                    animateRows={true}
                    enableCellChangeFlash={true}
                    enableColResize={true}
                    rowModelType={this.props.rowModelType}
                    onGridReady={this.props.onGridReady}
                    onCellValueChanged={this.props.onCellValueChanged}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>`}
                    overlayNoRowsTemplate={`<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">This is a custom 'no rows' overlay</span>`}
                >
                </AgGridReact>
            </div>
        );
    }
}
