import React from 'react';
import {Route, Routes,} from "react-router-dom";
import './App.css';
import AppHeader from './AppHeader';
import AppLogin from "./AppLogin";
import AppHome from "./AppHome";
import Alert, {sendErrorAlert} from "./Alert";
import AppDataList from "./AppDataList";
import Footer from './Footer';
import * as utils from "./utils";
import axios from "axios";

const CONNECTED_KEY = 'connected';

const token = utils.getToken()
if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            connected: null,
            descriptions: []
        };
        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    async componentDidMount() {
        let result = await utils.get('/api/crud/descriptions')
            .then(res => res.data);
        console.log('result', result);
        if (result.code && result.code / 100 !== 2) {
            sendErrorAlert({
                title: 'User List',
                message: result.message
            });
        } else {
            this.setState({
                descriptions: result
            });
        }
        const token = utils.getToken();
        this.setState({[CONNECTED_KEY]: token});
        /*utils.get('/api/auth')
            .then(result => {
                let {data} = result;
                if (data.code && data.code / 100 !== 2) {
                    sendErrorAlert({
                        title: 'Login error',
                        message: data.message
                    });
                    window.location.hash = '#/login';
                    return null;
                } else {
                    this.setState({[CONNECTED_KEY]: data});
                    localStorage.setItem(CONNECTED_KEY, data);
                }
            })
            .catch(error => {
                let {data} = error.response;
                console.error('error', data);
                sendErrorAlert({
                    title: 'Login error',
                    message: data.message
                });
            });*/
    }

    onLogin(event) {
        console.log('event', event);
        let code = (event.status || event.result?.code) || 200;
        let message = event.message || event.result?.message
        console.log('code', code);
        console.log('message', message);
        if (code / 100 !== 2) {
            this.setState({[CONNECTED_KEY]: null});
            sendErrorAlert({
                title: 'Login error',
                message: message
            });
        } else {
            this.setState({[CONNECTED_KEY]: event.result});
            window.location.hash = '#/';
        }
    }

    onLogout(event) {
        console.log('event', event);
        utils.remove('/api/auth')
            .catch(error => {
                let {data} = error.response;
                console.error('error', data);
                sendErrorAlert({
                    title: 'Login error',
                    message: data.message
                });
            });
        utils.removeToken();
        this.setState({[CONNECTED_KEY]: null});
        window.location.hash = '#/login';
    }

    render() {
        return (
            <>
                <Alert/>
                <AppHeader items={this.state.descriptions} connected={this.state.connected} onLogout={this.onLogout}/>
                <div className="App">
                    <Routes>
                        <Route exact path="/" element={<AppHome />} />
                        {this.state.connected && <Route path="/data/:path"
                                                        element={<AppDataList descriptions={this.state.descriptions}/>}
                        />}
                        <Route path="/login" element={<div className="text-center text-md-left g-py-50">
                            <AppLogin onLogin={this.onLogin}/>
                        </div>}/>
                    </Routes>
                </div>
                <Footer/>
            </>
        );
    }
}

export default App;
