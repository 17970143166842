import React from "react";
import {ICellRendererReactComp} from "ag-grid-react";
import {Checkbox} from "@mui/material";

export default class CheckboxRender extends React.Component implements ICellRendererReactComp {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, value) {
        this.props.setValue(value);
    }

    get value() {
        return this.props.value
    }

    render() {
        return (
            <Checkbox checked={this.value} onChange={this.handleChange}/>
        );
    }
}