import React from 'react';

import logo from './logo.png';

class AppHome extends React.Component {
    render() {
        return (
            <>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                </header>
            </>
        );
    }
}

export default AppHome;