import React from "react";
import {ICellRendererReactComp} from 'ag-grid-react'
import RemoveIcon from "@mui/icons-material/Delete";
import IconButton from '@mui/material/IconButton';

export default class ItemDelete extends React.Component implements ICellRendererReactComp {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        console.log('event', event);
        if (this.props.colDef.onDelete) {
            this.props.colDef.onDelete(this.props);
        }
    }

    render() {
        return (
            <IconButton size="small"
                 color="error"
                 aria-label="remove"
                 onClick={this.onClick}>
                <RemoveIcon/>
            </IconButton>
        );
    }
}