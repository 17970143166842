import React, {useCallback, useEffect} from "react";
import {
    Box,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField
} from "@mui/material";


export function ColumnSelector(props) {
    const [checked, setChecked] = React.useState(props.items || []);
    const [filterText, setFilterText] = React.useState("");
    const [hasChanged, setHasChanged] = React.useState(false);

    useEffect(() => {
        setChecked(props.items);
    }, [props.items]);

    const sendStatus = useCallback(() => {
        if (typeof props.onSelectionChanged === "function") {
            props.onSelectionChanged(checked)
            setHasChanged(false)
        }
    }, [checked, props]);

    useEffect(() => {
        if (hasChanged) {
            sendStatus();
        }
    }, [checked, sendStatus, hasChanged]);

    const handleToggle = (value) => () => {
        //const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        value.hide = !value.hide
        value.ignored = value.hide
        setHasChanged(true);
        setChecked(newChecked);
    };
    const numberOfChecked = () => checked.filter(i => !i.hide).length;
    const handleToggleAll = () => () => {
        if (numberOfChecked() === checked.length) {
            let newList = [...checked]
            newList.forEach(item => item.hide = true)
            setHasChanged(true);
            setChecked(newList);
        } else {
            let newList = [...checked]
            newList.forEach(item => item.hide = false)
            setHasChanged(true);
            setChecked(newList);
        }
    };
    const customList = (title) => {
        return <Card>
            <Box component="form" noValidate margin={1}
                 autoComplete="off">
                <TextField placeholder={"Quick Filter"} fullWidth
                           onChange={(event) => setFilterText(event.target.value)}/>
            </Box>
            <CardHeader
                sx={{px: 2, py: 1}}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll()}
                        checked={numberOfChecked() === checked.length && checked.length !== 0}
                        indeterminate={
                            numberOfChecked() !== checked.length && numberOfChecked() !== 0
                        }
                        disabled={checked.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked()}/${checked.length} selected`}
            />
            <Divider/>
            <List
                sx={{
                    width: props.height || "auto",
                    height: props.height || '710px',
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {checked.filter(item => item.headerName.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
                    // .sort((a, b) => (a.hide === true ? 1 : 0) - (b.hide === true ? 1 : 0))
                    .map((value) => {
                        const labelId = `transfer-list-all-item-${value.field}-label`;

                        return (
                            <ListItemButton
                                key={value.field}
                                role="listitem"
                                onClick={handleToggle(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={!value.hide}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.headerName}/>
                            </ListItemButton>
                        );
                    })}
            </List>
        </Card>
    };
    return (
        customList("Select Columns")
    );
}