import React from "react";
import {Autocomplete, TextField} from "@mui/material";
import OptionRender from "./OptionRender";

export default class PrimitiveOptionRender extends OptionRender {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, value) {
        this.props.setValue(value?.value);
    }

    get value() {
        console.log("this.props.value", this.props.value)
        if (!this.props.value) {
            return {value: '', label: ''};
        }
        return this.state.options.find(it => it.value === this.props.value) || {
            value: this.props.value,
            label: this.props.value
        };
    }

    render() {
        return (
            <Autocomplete
                onChange={this.handleChange}
                value={this.value}
                loading={this.state.loading}
                open={this.state.open}
                onOpen={() => {
                    this.setState({
                        open: true
                    });
                }}
                onClose={() => {
                    this.setState({
                        open: false
                    });
                }}
                isOptionEqualToValue={(opt, val) => opt.value === val.value}
                getOptionLabel={option => `${option.label}`}
                options={this.state.options}
                renderOption={(props, option) => <option {...props} key={option.value}
                                                         value={option.value}>{option.label}</option>}
                freeSolo
                renderInput={
                    (params) => <TextField {...params}
                                           // label={this.props.colDef.headerName}
                    />
                }
            />
        );
    }
}