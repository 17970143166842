import React from 'react';

class Footer extends React.Component {
    render() {
        return <footer className="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 text-center text-md-left g-mb-10 g-mb-0--md">
                        <div className="d-lg-flex">
                            <small
                                className="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">{new Date().getFullYear()} &copy; All
                                Rights Reserved.
                            </small>
                            <ul className="u-list-inline">
                                <li className="list-inline-item">
                                    <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/#"
                                       onClick={(e) => e.preventDefault()}>Privacy Policy</a>
                                </li>
                                <li className="list-inline-item">
                                    <span>|</span>
                                </li>
                                <li className="list-inline-item">
                                    <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/#"
                                       onClick={(e) => e.preventDefault()}>Terms of Use</a>
                                </li>
                                <li className="list-inline-item">
                                    <span>|</span>
                                </li>
                                <li className="list-inline-item">
                                    <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/#"
                                       onClick={(e) => e.preventDefault()}>License</a>
                                </li>
                                <li className="list-inline-item">
                                    <span>|</span>
                                </li>
                                <li className="list-inline-item">
                                    <a className="g-color-white-opacity-0_8 g-color-white--hover" href="/#"
                                       onClick={(e) => e.preventDefault()}>Support</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 align-self-center">
                        <ul className="list-inline text-center text-md-right mb-0">
                            <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                title="Facebook">
                                <a target="_blank_31415" href="https://www.facebook.com/Mcicheick"
                                   className="g-color-white-opacity-0_5 g-color-white--hover">
                                    <i className="fa fa-facebook"/>
                                </a>
                            </li>
                            <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                title="Linkedin">
                                <a target="_blank_31415"
                                   href="https://www.linkedin.com/in/cheick-mahady-sissoko-45482b63"
                                   className="g-color-white-opacity-0_5 g-color-white--hover">
                                    <i className="fa fa-linkedin"/>
                                </a>
                            </li>
                            <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                title="Twitter">
                                <a target="_blank_31415" href="https://twitter.com/mcicheick"
                                   className="g-color-white-opacity-0_5 g-color-white--hover">
                                    <i className="fa fa-twitter"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    }
}

export default Footer;