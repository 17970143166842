import axios from "axios";

export const X_AUTH_TOKEN = 'x-auth-token';

export function storeToken(headers) {
    if (headers) {
        let token = headers[X_AUTH_TOKEN] ? headers[X_AUTH_TOKEN] : headers.get(X_AUTH_TOKEN);
        localStorage.setItem(X_AUTH_TOKEN, token);
    }
}

export function removeToken() {
    localStorage.removeItem(X_AUTH_TOKEN);
}

export function storeObject(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getObject(key) {
    return JSON.parse(localStorage.getItem(key))
}

export function removeObject(key) {
    localStorage.removeItem(key);
}

export function getToken() {
    return localStorage.getItem(X_AUTH_TOKEN);
}

export function get(path, config = {}) {
    return axios.get(path, config);
}

export function post(path, data, config = {}) {
    return axios.post(path, data, config);
}

export function put(path, data, config = {}) {
    return axios.put(path, data, config);
}

export function remove(path, config = {}) {
    return axios.delete(path, config);
}

export function parseQueryString() {
    var queryString = window.location.search || window.location.hash || '';

    let index = queryString.indexOf('?');
    if (index >= 0) {
        queryString = decodeURI(queryString.substring(index + 1));
    }
    //queryString = decodeURI(queryString.substring(index));

    var params = {}, queries, temp, i, l;

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }

    return params;
}

export const isPrimitive = (type) => {
    return ["Double", "Long", "String", "string", "Date", "Integer", "text", "number", "date"].includes(type);
}

export const getType = (type) => {
    // 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions'
    let mapping = {
        "Double": "number",
        "Long": "number",
        "Integer": "number",
        "String": "string",
        "text": "string",
        "Date": "date",
        "Boolean": "boolean",
    }
    // if (isPrimitive(type)) {
    //     return 'text'
    // }
    return mapping[type] || type
}