import React, {useState} from 'react';
import {AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import LOGO from "./brand.png"
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

function AppHeader(props) {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigate = useNavigate();

    const goTo = (path) => {
        navigate(path);
        handleCloseNavMenu();
    }

    let items = (props.items || []).sort((a, b) => a.order - b.order);
    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to="/">
                        <img src={LOGO} alt={"Taxi"}/>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        {props.connected && <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {items.map((item) => (
                                <MenuItem key={item.name} onClick={() => goTo('/data/' + item.path)}>
                                    <Typography textAlign="center">{item.value}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>}
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {props.connected && items.map((item) => (
                            <Button
                                key={item.name}
                                onClick={() => goTo('/data/' + item.path)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {item.value}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Button onClick={props.connected === null ? () => goTo("/login") : props.onLogout}
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                            {props.connected === null ? "Log In" : "Log Out"}
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default AppHeader;